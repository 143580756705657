import React, { Component } from 'react'
import Footer from '../components/Common/Footer'
import Header from '../components/Common/Header'
import SEO from '../components/Common/SEO'
import Terms from '../components/Terms'

export default class terms extends Component {
    render() {
        return (

            <div id="scrollable" className='no-snap'>
                <Header isTransparent={false} isLoaded={true} threshold={0.01} />
                <SEO
                    title='Verse Estate - Terms and conditions'
                />
                    <div className='snap'>
                        <Terms />
                    </div>
                <Footer />
            </div>
        )
    }
}
